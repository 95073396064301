<template>
    <div class="investment-calculator">
        <div class="investment-calculator-inner innerContainer">
            <div class="left">
                <h2 class="header-title luxury">{{ title }}</h2>
                <div class="item">
                    <p class="label fira">{{ $fn.tr("Projects") }}</p>
                    <el-select
                        @change="changeProject"
                        ref="projectSelect"
                        v-model="activeProjectId">
                        <el-option
                            v-for="item in projects"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="item">
                    <p class="label fira">{{ $fn.tr("Apartment Type") }}</p>
                    <el-select
                        ref="apartmentSelect"
                        v-model="activeTypeId">
                        <el-option
                            v-for="item in activeProject.types"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="item">
                    <p class="label fira">{{ $fn.tr("Total invested") }}</p>
                    <div class="inp">
                        <p class="currency fira">{{ currentCurrencyIcon }}</p>
                        <input
                            :value="activeType.value"
                            disabled
                            type="number"
                        />
                        <div
                            class="price-cont"
                            @click="priceSelect = !priceSelect"
                            :class="{ active: priceSelect }"
                        >
                            <div class="top">
                                <p class="price fira bold">
                                    {{ currentCurrency }}
                                </p>
                                <div class="icon">
                                    <svg
                                        width="11"
                                        height="8"
                                        viewBox="0 0 11 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.03516 1.16016L5.53516 6.16016L10.0352 1.16016"
                                            stroke="white"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div class="content">
                                <p
                                    class="fira bold"
                                    v-for="(item, index) in currencyArr"
                                    :key="index"
                                    @click="setCurrency(item)"
                                >
                                    <b v-if="item !== currentCurrency">{{
                                            item
                                        }}</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="header">
                    <span class="title fira">{{ $fn.tr('Yearly net income') }}</span>
                </div>
                <div class="price-container">
                    <p class="price luxury">{{ currentCurrencyIcon }} {{ income }}</p>
                </div>
                <div class="list-item">
                    <div class="response-item">
                        <span class="header-title fira">{{ $fn.tr('Payback period') }}:</span>
                        <span class="description fira">{{ activeType.paybakc_period }}</span>
                    </div>
                    <div class="response-item">
                        <span class="header-title fira">{{ $fn.tr('interest free installment') }}:</span>
                        <span class="description fira">{{ activeType.interest_free_installment }}</span>
                    </div>
                    <div class="response-item">
                        <span class="header-title fira">{{ $fn.tr('down payment') }}:</span>
                        <span class="description fira">{{ activeType.down_payment }}</span>
                    </div>
                    <div class="response-item">
                        <span class="header-title fira">{{ $fn.tr('monthly paymant') }}:</span>
                        <div class="description fira">
                            <p class="currency fira">{{ currentCurrencyIcon }}</p>
                            {{ activeType.monthlyPaymant }}
                        </div>
                    </div>
                </div>
                <div @click="changeUrl" class="download-container">
                    <svg width="38" height="11" viewBox="0 0 38 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M31.8652 9.17275C31.5964 9.43665 31.5746 9.88829 31.8166 10.1815C32.0585 10.4747 32.4726 10.4985 32.7415 10.2346L37.6484 5.41797L32.7415 0.601335C32.4726 0.337437 32.0585 0.361207 31.8166 0.654428C31.5746 0.947648 31.5964 1.39928 31.8652 1.66318L35.0279 4.76758H0.65C0.291015 4.76758 0 5.05859 0 5.41758C0 5.77656 0.291015 6.06758 0.65 6.06758H35.0287L31.8652 9.17275Z"
                              fill="#F40146"/>
                    </svg>

                    <span class="fira title">{{ activeType.urlTitle }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {ref, Ref} from "vue";
import {
    ElSelect, ElOption,
} from 'element-plus'
import func from "../../../helpers/func";
import {useMenuStore} from "../../../store/pinia/menu";
import {useRouter} from "vue-router";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
let router = useRouter()
const currentCurrencyIcon = ref("$");
const priceSelect = ref(false);
const currentCurrency = ref('USD');
const currencyArr = ref(["USD", "GEL"]);
const setCurrency = (item) => {
    currentCurrency.value = item;
    if (item === "USD") {
        currentCurrencyIcon.value = "$";
    } else {
        currentCurrencyIcon.value = "₾";
    }
};
let store = useMenuStore()
let scroller = store.Loco
let categories = ref([{id: 12, title: 'test'}])
let title = computed(() => props.contentData?.conf?.header?.title)
let item = computed(() => {
    let item = props.contentData.data?.list?.[0]

    return {
        ...item,
        mobile: item?.images?.[0]?.devices?.mobile,
        desktop: item?.images?.[0]?.devices?.desktop,
        tablet: item?.images?.[0]?.devices?.tablet,
        urlTitle: item.url?.title,
        url: item.url?.url,
    }
})
let investmentCalculatorData = computed(() => store.indx?.terms?.investment_calculator)
let usd = computed(() => store?.indx.usd?.usd)
let projects = computed(() => investmentCalculatorData.value
    ?.filter(item => item.pid === 0)?.map(item => {
        return {
            ...item,
            types: investmentCalculatorData.value?.filter(type => type.pid === item.id)?.map(item => ({
                    ...item,
                    value: currentCurrency.value === 'GEL' ? Math.floor(item.total_investment_value * usd.value)
                        : item.total_investment_value,
                    monthlyPaymant: currentCurrency.value === 'GEL' ? Math.floor(item.monthly_paymant * usd.value)
                        : item.monthly_paymant,
                    urlTitle: item.url.title,
                    link: item.url.url,
                }
            ))
        }
    }))
let activeProjectId = ref(0)
let activeProject = computed(() => projects.value.find(item => item.id === activeProjectId.value) || {})
let activeTypeId = ref({})
let activeType = computed(() => activeProject.value?.types?.find(item => item.id === activeTypeId.value) || {})
let apartmentTypes = ref([])
let apartmentSelect = ref('')
let projectSelect = ref('')
const changeProject = () => {
    activeTypeId.value = activeProject.value?.types?.[0].id
}
const changeUrl = () => {
    router.push({
        path: activeType.value.link,
    })
}
const scrollEvent = () => {
    scroller?.on("scroll", () => {
        apartmentSelect.value?.blur()
        projectSelect.value?.blur()
    })

}

function formatMoney(number) {
    return (Math.round(number * 100) / 100).toLocaleString();
}

let income = computed(() => formatMoney(Math.floor(activeType.value.value / activeType.value.paybakc_period)))

onMounted(() => {
    activeProjectId.value = projects.value[0]?.id
    activeTypeId.value = projects.value[0]?.types?.[0].id
    scrollEvent()
})
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.investment-calculator {
    background: $primaryNavy;
    margin-top: 89px;
    color: white;
    padding: 134px 0 189px;

    .investment-calculator-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 1023px) {
            display: block;
        }
    }

    .left {
        width: 55%;
        @media only screen and (max-width: 1023px) {
            width: 100%;
        }

        .header-title {
            font-weight: 400;
            font-size: 34px;
            text-transform: uppercase;
        }

        .investment-calculator-inner {
            display: flex;
            align-items: center;
        }

        .inp {
            -webkit-appearance: none;
            border-radius: 5px;

            .currency {
                z-index: 9;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 20px;
                font-size: 16px;
            }

            color: white;
            background: transparent;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            position: relative;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 15px;
            padding-top: 15px;
            border: 1px solid #8c8c8c;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                padding-left: 35px;
                padding-right: 20px;
                font-size: 16px;
                color: white !important;
                opacity: 1 !important;
                background: transparent;

                &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }

            .icon {
                transition: 0.4s;
                transform: rotate(0deg);
            }

            &.active {
                .icon {
                    transform: rotate(180deg);
                }

                .show-cont {
                    opacity: 1 !important;
                    transform: translateY(0px) !important;
                    pointer-events: all !important;
                }
            }

            .show-cont {
                pointer-events: none;
                position: absolute;
                top: 60px;
                height: 200px;
                overflow: auto;
                left: 0;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                background-color: #ffffff;
                z-index: 2;
                border: 2px solid #aaaaaa;
                transform: translateY(-10px);
                opacity: 0;
                transition: 0.4s;
                box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.31);
                width: 100%;

                p {
                    transition: 0.4s;
                    margin-bottom: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0px;
                    }

                    cursor: pointer;
                    @media (min-width: 1024px) {
                        &:hover {
                            background-color: #aaaaaa;
                        }
                    }
                }
            }

            .price-cont {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);

                &.active {
                    .content {
                        opacity: 1;
                        transform: translateY(0px);
                        pointer-events: all;
                    }

                    .icon {
                        transform: rotate(180deg) !important;
                    }
                }

                .content {
                    position: absolute;
                    left: 0;
                    top: 20px;
                    transition: 0.4s;
                    transform: translateY(-10px);
                    pointer-events: none;
                    opacity: 0;

                    p {
                        cursor: pointer;
                    }
                }

                .top {
                    cursor: pointer;

                    .icon {
                        margin-left: 2px;
                        transform: rotate(0deg);
                        transition: 0.4s;
                    }

                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .item:deep {
            margin-top: 13px;

            &:first-child {
                margin-top: 0;
            }

            .label {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 8px;
            }

            .el-select {
                width: 100%;
                background: transparent;

                .el-input {
                    border: unset !important;
                }

                .el-input__inner {
                    color: white;
                    font-family: 'FiraGO';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                }

                .el-input__wrapper {
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    border: 1px solid #8c8c8c;
                    box-shadow: unset !important;
                    background: transparent;
                }

            }
        }

    }

    .download-container {
        display: flex;
        align-items: center;
        margin-top: 39px;
        cursor: pointer;

        &:hover {
            svg {
                animation: goBack .45s ease-out;
            }

            .title {
                opacity: .6;
            }
        }

        .title {
            margin-left: 13px;
            font-weight: 700;
            font-size: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #F40146;
        }
    }

    .list-item {
        margin-top: 13px;

        .response-item {
            border-top: 2px solid rgba(24, 34, 54, 0.2);
            margin-top: 13px;

            .header-title {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                margin-top: 12px;
                display: block;
            }

            .description {
                font-style: normal;
                font-weight: 500;
                font-size: 21px;
                margin-top: 8px;
                display: flex;
                align-items: center;
            }
        }
    }

    .right {
        width: 35%;
        background: #FFFFFF;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        padding: 39px 42px;
        color: $primaryNavy;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            padding: 25px;
        }
        @media only screen and (max-width: 1023px) {
            width: 100%;
            margin-top: 34px;
        }

        .header-title {
            color: $primaryNavy !important;
        }

        .header {
            padding-bottom: 21px;
            border-bottom: 2px solid rgba(24, 34, 54, 0.2);
            color: $primaryNavy;

            .title {
                font-weight: 400;
                font-size: 16px;
                color: $primaryNavy !important;
            }
        }

        .price-container {
            margin-top: 21px;

            .price {
                font-weight: 400;
                font-size: 55px;
                text-transform: uppercase;
                @media only screen and (max-width: 1440px) {
                    font-size: 34px;
                }
            }

            .description {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                margin-top: 21px;
                color: $primaryNavy;
            }
        }

        .picture {
            margin-top: 85px;
            width: 100%;

            img {
                width: 100%;
                height: 187px;
                object-fit: cover;
            }
        }
    }

    @keyframes goBack {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(5px);
        }
        100% {
            transform: translateX(0);
        }
    }

}
</style>

<style lang="scss">
.el-select-dropdown {
    .el-select-dropdown__item {
        span {
            font-family: 'FiraGO';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}
</style>
